import { Text, View } from "@react-pdf/renderer";
import Gas from "../../../../utils/data-classes/Gas";
import { shared } from "../challenge-report-styles";

export function Strong({ children }: { children: React.ReactNode; }) {
  return <Text style={{ fontWeight: 600 }}>{children}</Text>;
}

export function GasText({ gas }: { gas: Gas; }) {
  const fontSize = (c: string) => shared.body.fontSize * (Number.isNaN(parseInt(c)) ? 1 : 0.6);
  return (
    <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', position: 'relative', }}>
      {Array.from(gas.gas).map((c, i) => <Text key={i} style={{ fontSize: fontSize(c) }}>{c}</Text>)}
    </View>
  );
}

export function condition(gas: Gas, isSIBO: boolean): string {
  if (isSIBO) {
    if (gas === Gas.H2) return "SIBO";
    return "IMO";
  }
  return "Malabsorption";
}

export function interpretation(gas: Gas, isSIBO: boolean, isPositive: boolean): string {
  if (isPositive) return `${condition(gas, isSIBO)} suggested`;
  return `${condition(gas, isSIBO)} not suggested`;
}

export function testCriteria(gas: Gas, isSIBO: boolean): string {
  if (gas === Gas.CH4 && isSIBO) return "Peak ≥ 10 ppm";
  if (gas === Gas.CH4) return "Rise above baseline ≥ 10 ppm";
  if (isSIBO) return "Rise above baseline in 90 mins ≥ 20 ppm";
  return "Rise above baseline ≥ 20 ppm";
}
