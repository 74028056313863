import React, { useState, useContext, useEffect } from 'react';
import Modal from '../../../components/modal/Modal';
import IFTAInput from '../../register/IFTAElements/IFTAInput/IFTAInput';
import IFTASelect from '../../register/IFTAElements/IFTASelect/IFTASelect';
import { useForm } from 'react-hook-form';
import { ReferringClinicianData } from './AddPatientDetails';
import { LoginContext } from '../../../contexts/LoginContext/LoginContext';
import { authFetch } from '../../../utils/utils';
import toast from 'react-hot-toast';
import usePostNominalLetters, { ApiPostNominalLetter } from "../../../hooks/usePostNominalLetters";
import useSalutations, { ApiSalutation } from "../../../hooks/useSalutations";
import useConnectedStaffs from "../../../hooks/useConnectedStaffs";
import FormErrorMessage from "../../../components/errors/FormErrorMessage/FormErrorMessage";
import styles from "../../../components/dialogs/ChallengePromptModal/ChallengePromptForm.module.scss";
import stylesTooltip from "../../../components/tooltip/Tooltip.module.scss";

interface AddNewClinicianModalProps {
  onClose: () => void;
  onSave: (clinician: ReferringClinicianData) => void;
}

export function AddNewClinicianModal({ onClose, onSave }: AddNewClinicianModalProps) {
  const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm<ReferringClinicianData>({
    defaultValues: { salutation: 'Dr.' }
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { state: { loginToken } } = useContext(LoginContext);
  const { refreshConnectedStaffs } = useConnectedStaffs();

  const { postNominalLetters, error: postNominalLettersError } = usePostNominalLetters();
  const { salutations, error: salutationsError } = useSalutations();

  const onSubmit = async (data: ReferringClinicianData) => {
    if (!loginToken) {
      console.error("No login token available");
      toast.error("Authentication error");
      return;
    }

    try {
      setIsSubmitting(true);
      const response = await authFetch(loginToken, '/api/add-referring-clinician', {
        method: 'POST',
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Failed to add referring clinician');
      }

      const result = await response.json();

      toast.success('Referring clinician added successfully');
      // Refresh referring clinician list after successfull addition of referring clinician
      refreshConnectedStaffs();
      onSave({
        ...data,
        stfid: result.stfid
      });
    } catch (error) {
      console.error('Failed to add clinician:', error);
      toast.error(`Failed to add referring clinician: ${error instanceof Error ? error.message : 'Unknown error'}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title="Add New Referring Clinician"
      open={true}
      onCancel={onClose}
      onOk={handleSubmit(onSubmit)}
      okText="Save"
      disabled={isSubmitting}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <IFTASelect id="salutation" label="Title" reg={register("salutation")} value={watch("salutation")} >
          <option value="">No Title</option>
          {salutations?.map((sal: ApiSalutation) => (
            <option key={sal.salId} value={sal.salutation}>{sal.salutation}</option>
          )) || <option disabled>Loading...</option>}
        </IFTASelect>

        <IFTAInput
          id="name"
          label="First Name*"
          reg={register("name", { required: "First name is required" })}
        />
        <FormErrorMessage errors={errors} name="name" />

        <IFTAInput
          id="surname"
          label="Last Name*"
          reg={register("surname", { required: "Last name is required" })}
        />
        <FormErrorMessage errors={errors} name="surname" />

        <IFTASelect id="postNominalLetter" label="Suffix" reg={register("postNominalLetter")}>
          <option value="">No Suffix</option>
          {postNominalLetters?.map((letter: ApiPostNominalLetter) => (
            <option key={letter.pnlId} value={letter.pnlCode}>{letter.pnlCode + ' (' + letter.pnlDescription + ')'}</option>
          )) || <option disabled>Loading...</option>}
        </IFTASelect>
      </form>
    </Modal>
  );
}