import { useContext, useEffect, useRef } from "react";
import { AUCInfo, ResultInfo } from "../../digest/challenges/ChalCardHeader";
import { MiniChallengesContext, MiniChallengesContextProvider } from "./MiniChallengesContext";
import s from "./MiniChallenges.module.scss";
import { useDateFormat } from "../../../hooks/useDateFormat";
import { Challenge } from "../../../utils/data-classes/challenge/Challenge";
import ChalChart from "../../digest/challenges/ChalChart";
import ChartHandler from "../../digest/ChartHandler";
import { Chart, ChartConfiguration } from 'chart.js';
import ChallengeChartOptions from '../../digest/challenges/ChallengeChartOptions';
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import Gas from "../../../utils/data-classes/Gas";
import MiniLayout from "../../../layouts/mini/MiniLayout";
import ErrorPage from "../../../components/errors/ErrorPage/ErrorPage";
import classNames from "classnames";
import { interpretation } from "../../../pdf-generator/templates/ChallengeReport/components/ChallengeReportUtil";
import { Information } from "../../../components/tooltips/Information/Information";
import MiniChallengesDataTable from './MiniChallengesDataTable';
 
export default function MiniChallenges() {
  return (
    <MiniChallengesContextProvider>
      <Body />
    </MiniChallengesContextProvider>
  );
}

function Body() {
  const { fetching, challenge, error } = useContext(MiniChallengesContext).state;
  if (error) return <ErrorPage code={error.code} message={error.message} layout={MiniLayout} />;
  return (
    <MiniLayout>
      <div className={s.challengesContainer}>
        {fetching && <Loading />}
        {!fetching && !challenge && <NoChallenges />}
        {!fetching && challenge && <ChallengesList challenge={challenge} />}
      </div>
    </MiniLayout>
  );
}

const Loading = () => {
  return (
    <div className={s.loading}>
      <LoadingSpinner />
    </div>
  );
};

const NoChallenges = () => {
  return (
    <div>
      <h2>No challenges found</h2>
    </div>
  );
};

const ChallengesList = ({ challenge }: { challenge: Challenge }) => {
  return (
    <div className={s.challengeList}>
      <ChalCardHeader />
      <ChalCard challenge={challenge} />
    </div>
  );
};

const ChalCardHeader = () => {
  return (
    <div className={`${s.chalHeaderCard} ${s.challengeCard} ${s.stickyContainer}`}>
      <div className={s.chalHeader}>
        <span>
          <LeftButton />
        </span>
        <span>Challenge</span>
        <span></span>
        <span>Baseline</span>
        <span>
          Area Under Curve <AUCInfo />
        </span>
        <span>
          Result <ResultInfo />
        </span>
        <span>
          Interpretation <InterpretationInfo />
        </span>
        <span>
          <RightButton />
        </span>
      </div>
    </div>
  );
};

type ChangeChallengeButtonProps = { 
  next: Challenge;
} & Omit<React.HTMLProps<HTMLButtonElement>, "type" | "className" | "onClick">;

function ChangeChallengeButton({ next, ...rest }: ChangeChallengeButtonProps) {
  const { dispatch } = useContext(MiniChallengesContext);
  return (
    <button
      type="button"
      className={s.directionButton}
      onClick={(e) => {
        e.preventDefault();
        dispatch({ type: "SET_CHALLENGE", payload: next });
      }}
      {...rest}
    />
  );
}

function RightButton() {
  const { challenges } = useContext(MiniChallengesContext).state;
  const index = useChallengeIndex();
  const disabled = index === -1 || index === challenges.length - 1;
  return (
    <ChangeChallengeButton next={challenges[index + 1]} disabled={disabled} title="Display older challenge">
      {">"}
    </ChangeChallengeButton>
  );
}

function LeftButton() {
  const { challenges } = useContext(MiniChallengesContext).state;
  const index = useChallengeIndex();
  const disabled = index === -1 || index === 0;
  return (
    <ChangeChallengeButton next={challenges[index - 1]} disabled={disabled} title="Display more recent challenge">
      {"<"}
    </ChangeChallengeButton>
  );
}

function useChallengeIndex() {
  const { challenges, challenge } = useContext(MiniChallengesContext).state;
  if (!challenge) return -1;
  return challenges.indexOf(challenge);
}

function InterpretationInfo() {
  return <Information data={<InterpretationData />} />;
}

function InterpretationData() {
  const { challenge } = useContext(MiniChallengesContext).state;
  if (!challenge) return <></>;
  const hydrogenCriteria = challenge.isSIBO()
    ? "Rise above baseline in 90 mins ≥ 20 ppm"
    : "Rise above baseline ≥ 20 ppm";
  const methaneCriteria = challenge.isSIBO()
    ? "Peak ≥ 10 ppm"
    : "Rise above baseline ≥ 10 ppm";
  return (
    <div>
      Positive Criteria:
      <ul>
        <li>
          - <b>Hydrogen:</b> {hydrogenCriteria}
        </li>
        <li>
          - <b>Methane:</b> {methaneCriteria}
        </li>
      </ul>
    </div>
  );
}

export function testCriteria(gas: Gas, isSIBO: boolean): string {
  if (gas === Gas.CH4 && isSIBO) return "Peak ≥ 10 ppm";
  if (gas === Gas.CH4) return "Rise above baseline ≥ 10 ppm";
  if (isSIBO) return "Rise above baseline in 90 mins ≥ 20 ppm";
  return "Rise above baseline ≥ 20 ppm";
}

function ChalCard({ challenge }: { challenge: Challenge }) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstanceRef = useRef<Chart | null>(null);
  const { state } = useContext(MiniChallengesContext);

  useEffect(() => {
    if (!canvasRef.current) return;

    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) return;

    const chartOptions: ChartConfiguration = ChallengeChartOptions(challenge);

    if (chartInstanceRef.current) {
      // Update existing chart
      chartInstanceRef.current.data = chartOptions.data!;
      chartInstanceRef.current.options = chartOptions.options || {};
      chartInstanceRef.current.update();
    } else {
      // Create new chart
      chartInstanceRef.current = new Chart(ctx, chartOptions);
    }

    // Cleanup function
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, [challenge]); // Re-run effect when challenge changes

  return (
    <div className={s.challengeCard}>
      <Strip challenge={challenge} />
      <div>
        <ChalChart canvasRef={canvasRef} id={s.challengeCanvasWrapper} />
      </div>
      <MiniChallengesDataTable challenge={challenge} symptoms={state.symptoms} />
    </div>
  );
}

/**
 * Challenge Strip with the overview
 */
function Strip({ challenge }: { challenge: Challenge }) {
  return (
    <div id={s.openChallengeStrip} className={s.chalInfo}>
      <span></span>
      <span className={s.chalLabelCell}>{<ChallengeSelect />}</span>
      <ResultTable challenge={challenge} className={s.chalCardResultTable} />
    </div>
  );
}

function ChallengeSelect() {
  const { state, dispatch } = useContext(MiniChallengesContext);
  const { challenges, challenge: selectedChallenge } = state;
  if (!selectedChallenge) return <></>;
  return (
    <select
      id="challenges-select"
      value={challenges.indexOf(selectedChallenge)}
      onChange={(e) => {
        dispatch({ type: "SET_CHALLENGE", payload: challenges[parseInt(e.target.value)] });
      }}
    >
      {challenges.map((challenge, i) => (
        <ChallengeSelectOption key={i} challenge={challenge} />
      ))}
    </select>
  );
}

function ChallengeSelectOption({ challenge }: { challenge: Challenge }) {
  const { challenges } = useContext(MiniChallengesContext).state;
  const date = challenge.createdOnDateTimeUTC.toLocal().toFormat(useDateFormat("date"));
  return (
    <option value={challenges.indexOf(challenge)}>
      {date} - {challenge.label} ({challenge.dose})
    </option>
  );
}

const ResultTable = ({ challenge, className }: { challenge: Challenge; className: string }): JSX.Element => (
  <div className={className}>
    <ResultTableRow challenge={challenge} gas={Gas.H2} />
    <ResultTableRow challenge={challenge} gas={Gas.CH4} />
  </div>
);

const ResultTableRow = ({ challenge, gas }: { challenge: Challenge; gas: Gas }): JSX.Element => {
  const hasGas = challenge.hasGas(gas);
  const handler = challenge.ppmHandler(gas);
  const isPositive = !!handler.positiveRise();
  return (
    <div>
      <span>{gas.fancy}</span>
      <span className={s.numeric}>
        <span>{hasGas && challenge.baselineTest.ppm(gas)}</span>
        <span>{hasGas && "ppm"}</span>
      </span>
      <span className={s.numeric}>
        <span>{hasGas && handler.areaUnderCurve().toFixed(0)}</span>
        <span>{hasGas && "ppm mins"}</span>
      </span>
      <span
        className={classNames({
          [s.numeric]: true,
          [s.positive]: isPositive,
          [s.negative]: !isPositive,
        })}
      >
        <span>{hasGas && handler.result()}</span>
        <span>{hasGas && "ppm"}</span>
      </span>
      <span className={isPositive ? s.positive : s.negative}>
        {challenge.hasGas(gas) && interpretation(gas, challenge.isSIBO(), isPositive)}
      </span>
    </div>
  );
};
