import React, { useContext, useEffect } from "react";
import s from "./AddPatient.module.scss";
import { LoginContext, LoginContextState } from "../../../contexts/LoginContext/LoginContext";
import { UseFormReturn } from "react-hook-form";
import LabelledCheckbox from "../../../components/inputs/LabelledCheckbox/LabelledCheckbox";
import { AddPatientContext } from "../../../contexts/AddPatientContext/AddPatientContext";
import LabelledRadioButton from "../../../components/inputs/typed-inputs/LabelledRadioButton";
import { Information } from "../../../components/tooltips/Information/Information";
import { PaymentMethod, AddPatientFormFieldValues } from "./AddPatient";
import { useAddPatientFormContext } from "./add-patient-util/useAddPatientFormContext";
import useStaffRole from "./add-patient-util/useStaffRole";

// Add prop type
interface AddPatientOrderDeviceProps {
  providerPreferences: ProviderPreference[];
}

export const AddPatientOrderDevice = ({ providerPreferences }: AddPatientOrderDeviceProps) => {
  const { state: loginState } = useContext(LoginContext);
  const { provider } = loginState;
  const {
    state: { processing, skus, orderOptions },
    dispatch,
  } = useContext(AddPatientContext);
  const isAffiliate = useStaffRole() === "affiliate";
  const form = useAddPatientFormContext();
  const { getValues } = form;

  useEffect(() => {
    // Set default values for the hidden fields
    form.setValue("device", true);
    form.setValue("deviceModel", "2");

    const paymentPref = providerPreferences?.find(pref => pref.ppId === 14);
       // If no preference set or val is "1", use patient-self-pay
    const defaultPayment = !paymentPref || paymentPref.val === "1" ? "patient-self-pay" : "individual-arrears";

    // Only use selfPayRequirement if it's required/forbidden, otherwise use the preference
    const requirement = selfPayRequirement(loginState);

    if (requirement === "required" || requirement === "forbidden") {
      registerPaymentMethod(form, requirement);
    } else {
      form.setValue("paymentMethod", defaultPayment);
    }
  }, [loginState, providerPreferences]);

  if (!provider || !skus || !orderOptions) return <></>;

  const scheduleFirstChallenge = getValues("scheduleFirstChallenge");

  return (
    <>
      <h1>Order Device</h1>
      <p className={s.subtitle}>
        Please select the substrate and payment method for this patient.
      </p>
      <div className={s.orderDevice}>
        <h3>Substrates</h3>
        <SubstrateOptions />
        {selfPayRequirement(loginState) === "optional" && <PaymentOptions />}
      </div>
      <div className={s.btnGroup}>
        <button
          type="button"
          className={s.btnSecondary}
          onClick={() => dispatch({ type: "SET_STAGE", payload: "PATIENT_DETAILS" })}
        >
          Back
        </button>
        {scheduleFirstChallenge ? (
          <button
            className={s.btnPrimary}
            type="button"
            disabled={processing}
            onClick={() => dispatch({ type: "SET_STAGE", payload: "SELECT_CHALLENGE" })}
          >
            Next
          </button>
        ) : (
          <button
            className={s.btnPrimary}
            type="submit"
            disabled={processing}
          >
            {processing ? "Processing..." : "Submit"}
          </button>
        )}
      </div>
    </>
  );
};


export const AddPatientOrderDevicePrompt = () => {
  const {
    state: { processing },
    dispatch,
  } = useContext(AddPatientContext);
  const form = useAddPatientFormContext();
  const onOrderDeviceClick = () => {
    form.setValue("device", true);
    dispatch({ type: "SET_STAGE", payload: "ORDER_DEVICE" })
  };

  return (
    <>
      <div className={s.promptTitle}>
        Do you want to continue without ordering a device for this patient?
      </div>
      <div className={s.orderPrompt}>
        A device cannot be ordered from the dashboard once the patient is added.
        You can provide the device to the patient in your office. Alternatively, order it now and FoodMarble will mail it to the patient.
      </div>
      <div className={s.btnGroup}>
        <button className={s.btnSecondary} type="submit" disabled={processing}>
          {processing ? "Processing..." : "Continue without ordering"}
        </button>
        <button className={s.btnPrimary} onClick={onOrderDeviceClick}>
          Order device
        </button>
      </div>
    </>
  );
};

function PaymentOptions() {
  const form = useAddPatientFormContext();
  return (
    <>
      <h3>Payment Method</h3>
      <ul className={s.radioButtons}>
        <li>
          <LabelledRadioButton form={form} path="paymentMethod" value="patient-self-pay">
            Patient self-pay
          </LabelledRadioButton>
          <span>
            <Information data="Select this option if your patient is paying for the order. We will email your patient for their billing details. The order will be sent to the patient." />
          </span>
        </li>
        <li>
          <LabelledRadioButton form={form} path="paymentMethod" value="individual-arrears">
            Add to our invoice
          </LabelledRadioButton>
          <span>
            <Information data="Select this option if you are paying for the order. We will email your patient for their shipping details. The order will be sent to the patient. The order amount will be added to your next invoice once the patient tests." />
          </span>
        </li>
      </ul>
    </>
  );
}
type TSelfPayRequirement = "required" | "forbidden" | "optional" | undefined;

const selfPayRequirement = ({ staff, support }: LoginContextState): TSelfPayRequirement => {
  if (!staff || !support) return undefined;
  if (!support.selfPay) return "forbidden";
  if (staff.role === "affiliate") return "required";
  return "optional";
};

const defaultPaymentMethod = (req: TSelfPayRequirement): PaymentMethod => {
  if (req === "forbidden") return "individual-arrears";
  return "patient-self-pay";
};

const registerPaymentMethod = (form: UseFormReturn<AddPatientFormFieldValues, any>, req: TSelfPayRequirement) => {
  form.setValue("paymentMethod", defaultPaymentMethod(req));
  if (req !== "required" && req !== "forbidden") return;
  form.register("paymentMethod", {
    validate: (val) => val === defaultPaymentMethod(req) || `Self pay is ${req}`,
  });
};

function DeviceModelOptions() {
  const form = useAddPatientFormContext();
  const {
    state: { orderOptions },
  } = useContext(AddPatientContext);
  const hydrogenMethane = orderOptions?.hydrogenMethane.device;
  return (
    <ul className={s.radioButtons}>
      {hydrogenMethane?.isActive && (
        <li>
          <LabelledRadioButton form={form} path="deviceModel" value="2">
            Hydrogen + Methane
          </LabelledRadioButton>
        </li>
      )}
    </ul>
  );
}

function SubstrateOptions() {
  const {
    state: { orderOptions },
  } = useContext(AddPatientContext);
  const form = useAddPatientFormContext();
  const device = "hydrogenMethane";
  const fodmaps = device ? orderOptions?.[device].fodmaps : undefined;
  const glucose = device ? orderOptions?.[device].glucose : undefined;
  return (
    <ul>
      {fodmaps?.isActive && (
        <li>
          <LabelledCheckbox form={form} name="fodmaps">
            Food Intolerance Kit (lactose, fructose, inulin & sorbitol)
          </LabelledCheckbox>
        </li>
      )}
      {glucose?.isActive && (
        <li>
          <LabelledCheckbox form={form} name="glucose">
            SIBO Test (Glucose × 2)
          </LabelledCheckbox>
        </li>
      )}
    </ul>
  );
}

function AddPatientWithoutDeviceCheckbox() {
  const { register } = useAddPatientFormContext();
  const id = "no-device-checkbox";
  return (
    <>
      <div>
        <h3>Others</h3>
      </div>
      <div className={s.noDevice}>
        <ul>
          <li>
            <input id={id} type="checkbox" {...register("addPatientWithoutDevice")} />
          </li>
        </ul>

        <div>
          <label htmlFor={id}>Add patient without ordering device</label>
          <label htmlFor={id}><strong>Important:</strong> Devices cannot be ordered from the dashboard once patients are added.</label>
        </div>
      </div>
    </>
  )
}

interface ProviderPreference {
  ppValId: number;
  ppId: number;
  prid: number;
  set_on: string;
  val: string;
}