import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import { Patient } from "../../../utils/data-classes/Patient";
import { AppError } from "../../../utils/utils";
import DialogNav from "../DialogNav/DialogNav";
import s from "./ChallengePromptForm.module.scss";
import { ChallengePromptContext } from "./ChallengePromptContext";
import { ClinicianRequestType } from "../../../utils/data-classes/ClinicianRequestType";
import { LanguageCode } from "../../../utils/data-classes/Language";
import { LanguageSelectWidget } from "../../menus/LanguageSelect/LanguageSelect";
import FormErrorMessage from "../../errors/FormErrorMessage/FormErrorMessage";
import RoundLabelledCheckbox from "./RoundLabelledCheckbox/RoundLabelledCheckbox";
import FilledRadioButton from "./FilledRadioButton/FilledRadioButton";
import { Information } from "../../tooltips/Information/Information";
import Modal from "../Modal/Modal";
import { SearchField } from "../../SearchField/SearchField";
import useConnectedStaffsAll from "../../../hooks/useConnectedStaffsAll";
import { authFetch } from "../../../utils/utils";
import { AddNewClinicianModal } from "../../../pages/home/add-patient/AddNewClinicianModal";
import { ReferringClinicianData } from "../../../pages/home/add-patient/AddPatientDetails";
import useDefaultTestDeadlinePreference from "../../../hooks/useDefaultTestDeadlinePreference";

const minLimits = {
  duration: {
    min: 1,
    max: 360,
  },
  breathInterval: {
    min: 1,
    max: 30,
  },
  testDeadline: {
    min: 1,
    max: 21,
  }
}

export const ChallengePromptModal = (props: ChallengePromptModalProps) => {
  const { state: { action, patient, error }, dispatch } = useContext(ChallengePromptContext);
  const isOpen = !!(action !== undefined && patient);
  const close = () => dispatch({ type: "RESET" });
  const submitAction = async (data: ChallengePromptFormFieldValues) => dispatch({
    type: "DO_ACTION",
    payload: { action: "CREATE", formData: data },
  });
  return (
    <Modal isOpen={isOpen} onRequestClose={close} contentLabel={props.contentLabel}>
      <div>
        <DialogNav onCloseButtonClick={close} />
        {error && <ChallengePromptError error={error} />}
        {!error && <>
          {action === "CREATE" && <ChallengePromptForm submitAction={submitAction} />}
          {action === "REMIND" && <ReminderForm />}
          {action === "CANCEL" && <DeletionForm />}
        </>}
      </div>
    </Modal>
  )
}

const ChallengePromptError = ({ error }: { error: AppError }) => {
  return (
    <div className={s.chalPromptForm}>
      <h1>Error {error.code}</h1>
      <p>{error.message}</p>
    </div>
  )
}

export const ChallengePromptForm = ({ submitAction }: ChallengePromptFormProps) => {
  const { state: promptState } = useContext(ChallengePromptContext);
  const { clinicianRequestTypesMap, patient: patientToPrompt, processing, success, error } = promptState;

  const [requestType, setRequestType] = useState<ClinicianRequestType>();

  const form = useForm<ChallengePromptFormFieldValues>({
    defaultValues: {
      crtId: undefined,
      lang: "en",
      durationMins: undefined,
      intervalMins: undefined,
      testDeadlineDays: undefined,
      finishChalOnTwoSuccessivePositive: false,
    }
  });
  const { handleSubmit, watch, setValue } = form;

  useEffect(() => {
    if (!clinicianRequestTypesMap) return;
    setRequestType(clinicianRequestTypesMap[watch("crtId")]);
  }, [watch("crtId")]);

  if (error) return <Error error={error} />

  if (!patientToPrompt || !clinicianRequestTypesMap) return <></>;

  if (success && requestType) return <CreatedPromptSuccess patient={patientToPrompt} type={requestType} />

  const onSubmit = async (data: ChallengePromptFormFieldValues) => {
    await submitAction(data);
  }

  const { sibo, csid, nonSibo } = getSplitRequestTypes(clinicianRequestTypesMap);

  return (
    <form className={s.chalPromptForm} onSubmit={handleSubmit(onSubmit)}>
      <BasicOptions form={form} sibo={sibo} csid={csid} nonSibo={nonSibo} />
      <AdvancedOptions form={form} requestType={requestType} />
      <button type="submit" disabled={processing}>
        {processing ? "Sending challenge prompt..." : "Submit"}
      </button>
    </form>
  );
}

const ClinicianSection = ({ form }: { form: UseFormReturn<ChallengePromptFormFieldValues> }) => {
  const { state: { patient } } = useContext(ChallengePromptContext);
  const { state: { loginToken, staff } } = useContext(LoginContext);
  // Load all staffs under the provider
  const { connectedStaffsAll, user } = useConnectedStaffsAll();
  const [searchValue, setSearchValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [showAddNewModal, setShowAddNewModal] = useState(false);

  useEffect(() => {
    const fetchPreferences = async () => {
      if (!patient?.ptid || !loginToken) return;

      try {
        const res = await authFetch(loginToken, `/api/patient-preferences/${patient.ptid}`, {
          signal: new AbortController().signal
        });
        const { preferences, error } = await res.json();
        if (error) throw new AppError(res.status, error);

        if (preferences?.referringStfid) {
          const defaultStaff = connectedStaffsAll?.find(s => s.stfid === preferences.referringStfid.toString());
          if (defaultStaff) {
            setSearchValue([defaultStaff.salutation, defaultStaff.firstName, defaultStaff.lastName].filter(Boolean).join(' '));
            form.setValue('referringStfid', parseInt(defaultStaff.stfid));
          }
        } else if (user) {
          setSearchValue([user.salutation, user.firstName, user.lastName].filter(Boolean).join(' '));
          form.setValue('referringStfid', parseInt(user.stfid));
        }
      } catch (err) {
        console.error('Error fetching patient preferences:', err);
      }
    };

    fetchPreferences();
  }, [patient?.ptid, user, loginToken]);

  const handleAddNew = () => {
    setShowAddNewModal(true);
    setIsOpen(false);
  };

  const handleNewClinicianSave = (newClinician: ReferringClinicianData) => {
    if (newClinician.stfid) {
      form.setValue('referringStfid', parseInt(newClinician.stfid));
      const newClinicianLabel = [
        newClinician.salutation,
        newClinician.name,
        newClinician.surname
      ].filter(Boolean).join(' ');
      setSearchValue(newClinicianLabel);
    }
    setShowAddNewModal(false);
  };

  return (
    <div className={s.clinicianField}>
      <div className={s.labelContainer}>
        <label className={s.clinicianLabel}>Clinician</label>
        <Tooltip data="The clinician's name displayed here will appear on the test report. You can edit this field if needed." />
      </div>
      <SearchField
        items={connectedStaffsAll?.map(staff => ({
          label: [staff.salutation, staff.firstName, staff.lastName].filter(Boolean).join(' '),
          value: staff,
          isBold: staff.link,
        })) || []}
        placeholder="Type to search for a referring clinician..."
        onSelect={(staff) => form.setValue('referringStfid', parseInt(staff.stfid))}
        onAddNew={handleAddNew}
        addNewText="Add new referring clinician"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        size="small"
      />

      {showAddNewModal && (
        <AddNewClinicianModal
          onClose={() => setShowAddNewModal(false)}
          onSave={handleNewClinicianSave}
        />
      )}
    </div>
  );
};

const SimpleForm = (props: SimpleFormProps) => {
  const { state: { loginToken } } = useContext(LoginContext);
  const { state: { action, patient: patientToPrompt, requestedChallenge, processing, success }, dispatch: challengePromptDispatch } = useContext(ChallengePromptContext);

  const form = useForm();

  useEffect(() => {
    if (!patientToPrompt || action === props.action) return;
    challengePromptDispatch({
      type: "INIT_CHALLENGE_FORM", payload: {
        action: props.action,
        patient: patientToPrompt,
        requestedChallenge,
      }
    });
  }, [patientToPrompt, requestedChallenge]);

  if (!loginToken || !patientToPrompt || !requestedChallenge) return <></>

  const onSubmit = async () => {
    challengePromptDispatch({
      type: "DO_ACTION",
      payload: { action: props.action }
    });
  }

  if (success) return <Success><p>{props.successMsg}</p></Success>

  return (
    <form className={s.reminderForm} onSubmit={form.handleSubmit(onSubmit)}>
      <h1>{props.title}</h1>
      <p>{props.description}</p>
      <div className={s.buttons}>
        <button type="button" disabled={processing} onClick={() => challengePromptDispatch({ type: "RESET" })}>No</button>
        <button type="submit" disabled={processing} title={props.title}>Yes</button>
      </div>
    </form>
  );
}

const ReminderForm = () => {
  const { requestedChallenge } = useContext(ChallengePromptContext).state;
  return <SimpleForm
    action="REMIND"
    title="Send Test Reminder"
    description={`Would you like to remind this patient to complete their ${requestedChallenge} test?`}
    successMsg="Test reminder sent successfully"
  />;
}

const DeletionForm = () => {
  const { requestedChallenge } = useContext(ChallengePromptContext).state;
  return <SimpleForm
    action="CANCEL"
    title="Cancel Requested Test"
    description={`Would you like to cancel this patient's ${requestedChallenge} test?`}
    successMsg="Test request cancelled successfully"
  />;
}

const getSplitRequestTypes = (requestTypesMap: Partial<Record<number, ClinicianRequestType>>): ClinicianRequestTypeSplit => {
  return Object.values(requestTypesMap).reduce<ClinicianRequestTypeSplit>((acc, cur) => {
    if (!cur) return acc;
    if (cur.isSIBO()) acc.sibo.push(cur);
    else if (cur.isCSID()) acc.csid.push(cur);
    else acc.nonSibo.push(cur);
    return acc;
  }, { sibo: [], csid: [], nonSibo: [] });
}

const SubstrateRadioButtons = ({ requestTypes, form }: SubstrateRadioButtonsProps) => {
  return (
    <>
      {requestTypes.map((type, i) => (
        <Controller
          key={i}
          control={form.control}
          name="crtId"
          render={({ field: { name, onChange } }) => <FilledRadioButton id={`substrate-radio-${type.label}`} label={type.label || ""} name={name} value={type.crtId} onChange={onChange} />}
          rules={{
            required: "Substrate is required",
          }}
        />
      ))}
    </>
  );
}

const BasicOptions = ({ form, sibo, csid, nonSibo }: {
  form: UseFormReturn<ChallengePromptFormFieldValues, any>;
  sibo: ClinicianRequestType[];
  csid: ClinicianRequestType[];
  nonSibo: ClinicianRequestType[];
}) => {
  const allTests = [
    ...sibo.map(test => ({ label: `SIBO - ${test.label || "Unnamed Test"}`, value: test.crtId })),
    ...csid.map(test => ({ label: `CSID - ${test.label || "Unnamed Test"}`, value: test.crtId })),
    ...nonSibo.map(test => ({ label: `Food Intolerance - ${test.label || "Unnamed Test"}`, value: test.crtId }))
  ];

  return (
    <div className={s.basicOptions}>
      <h1>Select Test</h1>
      <p>Please select the substrate you would like the patient to use for their test:</p>
      <select
        className={s.selectDropdown}
        onChange={(e) => form.setValue("crtId", parseInt(e.target.value))}
        value={form.watch("crtId") || ""}
      >
        <option value="" disabled>Select a test...</option>
        {allTests.map((test, i) => (
          <option key={i} value={test.value}>{test.label}</option>
        ))}
      </select>
      <FormErrorMessage errors={form.formState.errors} name="crtId" />
      <div className={s.languageSelect}>
        <LanguageSelectWidget control={form.control} name="lang" selected={form.watch("lang")} />
      </div>
      <FormErrorMessage errors={form.formState.errors} name="lang" />
    </div>
  );
};


const AdvancedOptions = ({ form, requestType }: { form: UseFormReturn<ChallengePromptFormFieldValues, any>, requestType: ClinicianRequestType | undefined }) => (
  <div className={s.advancedOptions}>
    <h1>Advanced Options</h1>
    <ClinicianSection form={form} />
    <p>To change the test parameters, use the dropdown menus below.</p>
    <div className={s.advancedOptionsInputs}>
      <TestDeadlineInput form={form} requestType={requestType} />
      <FormErrorMessage errors={form.formState.errors} name="testDeadlineDays" />
      <DurationInput form={form} requestType={requestType} />
      <FormErrorMessage errors={form.formState.errors} name="durationMins" />
      <BreathIntervalInput form={form} requestType={requestType} />
      <FormErrorMessage errors={form.formState.errors} name="intervalMins" />
      <FinishChalCheckbox form={form} />
      <FormErrorMessage errors={form.formState.errors} name="finishChalOnTwoSuccessivePositive" />
    </div>
  </div>
);

const testDeadlineDaysOptions = [7, 14, 21];

const TestDeadlineInput = ({ form, requestType }: { form: UseFormReturn<ChallengePromptFormFieldValues, any>, requestType: ClinicianRequestType | undefined }) => {
  const { watch, register, setValue } = form;
  // Call the hook directly in this component
  const { defaultTestDeadlinePreference } = useDefaultTestDeadlinePreference();
  
  useEffect(() => {
    // Only set the value if there's a requestType selected and testDeadlineDays isn't already set
    if (requestType) {
      // If we have a preference from the database, use it
      if (defaultTestDeadlinePreference && defaultTestDeadlinePreference.length > 0) {
        const firstDeadline = defaultTestDeadlinePreference[0].testDeadline;
        setValue("testDeadlineDays", parseInt(firstDeadline));
      } 
      // If no preference and no value set yet, use default
      else if (!watch("testDeadlineDays")) {
        setValue("testDeadlineDays", 14);
      }
    }
  }, [requestType, defaultTestDeadlinePreference, setValue, watch]);

  return (
    <div className={s.numericInputContainer}>
      <label htmlFor="chal-testDeadline">Test deadline:</label>
      <select id="chal-testDeadline" value={watch("testDeadlineDays") || ""} disabled={!watch("crtId")} {...register("testDeadlineDays", {
        required: "testDeadline is required",
        valueAsNumber: true,
        min: {
          value: minLimits.testDeadline.min,
          message: `Minimum testDeadline in days is ${minLimits.testDeadline.min}`
        },
        max: {
          value: minLimits.testDeadline.max,
          message: `Maximum testDeadline in days is ${minLimits.testDeadline.max}`,
        }
      })}>
        {!requestType && <option value=""></option>}
        {requestType && testDeadlineDaysOptions.map((n, i) => <option key={i} value={n}>{n} days</option>)}
      </select>
      <Tooltip data="The patient will be requested to complete the test within this timeframe, and will be reminded to do so. They will still be able to test after this deadline." />
    </div>
  );
}

const durationMinsOptions = [120, 180, 240, 300];

const DurationInput = ({ form, requestType }: { form: UseFormReturn<ChallengePromptFormFieldValues, any>, requestType: ClinicianRequestType | undefined }) => {
  const { watch, register, setValue } = form;

  useEffect(() => {
    setValue("durationMins", defaultDuration(requestType));
  }, [requestType]);

  return (
    <div className={s.numericInputContainer}>
      <label htmlFor="chal-duration">Test duration:</label>
      <select id="chal-duration" defaultValue="" disabled={!watch("crtId")} {...register("durationMins", {
        required: "Duration is required",
        valueAsNumber: true,
        min: {
          value: minLimits.duration.min,
          message: `Minimum duration in minutes is ${minLimits.duration.min}`
        },
        max: {
          value: minLimits.duration.max,
          message: `Maximum duration in minutes is ${minLimits.duration.max}`,
        }
      })}>
        {!requestType && <option value=""></option>}
        {requestType && durationMinsOptions.map((n, i) => <option key={i} value={n}>{n} minutes</option>)}
      </select>
      <Tooltip data="Gap between first reading and last reading." />
    </div>
  );
}

function defaultDuration(requestType: ClinicianRequestType | undefined): number {
  switch (requestType?.label) {
    case "Lactulose": return 120;
    case "Glucose": return 120;
    default: return 180;
  }
}

function defaultInterval(requestType: ClinicianRequestType | undefined): number {
  switch (requestType?.label) {
    case "Inulin": return 30;
    default: return 15;
  }
}

const breathIntervalOptions = [15, 20, 30];

function allowedIntervals(duration: number) {
  if (duration >= 300) return [30];
  return breathIntervalOptions;
}

const BreathIntervalInput = ({ form, requestType }: { form: UseFormReturn<ChallengePromptFormFieldValues, any>, requestType: ClinicianRequestType | undefined }) => {
  form.setValue("intervalMins", defaultInterval(requestType));
  return (
    <div className={s.numericInputContainer}>
      <label htmlFor="breath-interval">Breath reading interval:</label>
      <select id="breath-interval" defaultValue={defaultInterval(requestType)} {...form.register("intervalMins", {
        required: "Breath reading interval is required",
        valueAsNumber: true,
        min: {
          value: minLimits.breathInterval.min,
          message: `Minimum breath reading interval minutes is ${minLimits.breathInterval.min}`,
        },
        max: {
          value: minLimits.breathInterval.max,
          message: `Maximum breath reading interval minutes is $${minLimits.breathInterval.max}`,
        }
      })}>
        {requestType && allowedIntervals(form.watch("durationMins")).map((n, i) => <option key={i} value={n}>{n} minutes</option>)}
      </select>
      <Tooltip data={getBreathIntervalTooltip(requestType, form.watch("durationMins"))} />
    </div>
  );
}

function getBreathIntervalTooltip(requestType: ClinicianRequestType | undefined, chalDuration: number): string {
  const baseTooltip = "Gap between each consecutive reading.";
  
  if (requestType?.label === "Inulin") 
    return `${baseTooltip} Breath interval will change to 15 minutes once a rise is detected. After 3 hours patients are asked if they would like to continue testing. Patients are permitted to eat a small low FODMAP meal at this point.`;
  
  if (chalDuration >= 300) 
    return `${baseTooltip} Breath interval will change to 15 minutes once a rise is detected.`;
  
  return baseTooltip;
}

const Tooltip = ({ data }: { data: string | undefined }) => {
  return <span>{data !== undefined && <Information data={data} aria-label="Information icon" />}</span>
}

const FinishChalCheckbox = ({ form }: { form: UseFormReturn<ChallengePromptFormFieldValues, any> }) => (
  <RoundLabelledCheckbox id="finish-challenge-checkbox" reg={form.register("finishChalOnTwoSuccessivePositive")} >
    Finish test when two successive breath readings reach positive criteria.
  </RoundLabelledCheckbox>
)

const CreatedPromptSuccess = ({ patient, type }: { patient: Patient, type: ClinicianRequestType }) => {
  const { state: { provider } } = useContext(LoginContext);

  const smsSent = patient.phone && provider?.hasPhoneAccess;

  return (
    <Success>
      <p>{type.label} test prompt email {smsSent ? " and SMS " : ""}sent to {patient.name}</p>
    </Success>
  );
}

const Success = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={s.success}>
      <h1>Success</h1>
      {children}
    </div>
  );
}

/**
 * @param {Object} props
 * @param {AppError} props.error 
 * @returns 
 */
const Error = ({ error }: { error: AppError }) => (
  <div className={s.error}>
    <h1>Error {error.code}</h1>
    <p>{error.message}</p>
  </div>
)

interface ChallengePromptModalProps {
  defaultLabel?: string,
  contentLabel?: string,
}

export interface ChallengePromptFormFieldValues {
  crtId: number;
  lang: LanguageCode;
  durationMins: number;
  intervalMins: number;
  testDeadlineDays: number;
  finishChalOnTwoSuccessivePositive: boolean;
  referringStfid?: number;
}

interface ChallengePromptFormProps {
  submitAction: (data: ChallengePromptFormFieldValues) => Promise<void>,
}

interface SubstrateRadioButtonsProps {
  requestTypes: ClinicianRequestType[],
  form: UseFormReturn<ChallengePromptFormFieldValues, any>,
}

type ClinicianRequestTypeSplit = {
  sibo: ClinicianRequestType[];
  csid: ClinicianRequestType[];
  nonSibo: ClinicianRequestType[];
}

interface SimpleFormProps {
  action: "REMIND" | "CANCEL";
  title: string;
  description: string;
  successMsg: string;
}