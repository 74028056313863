// SelectChallenge.tsx - behaves like AddPatientOrderDevice
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { useAddPatientFormContext } from "./add-patient-util/useAddPatientFormContext";
import { AddPatientContext } from "../../../contexts/AddPatientContext/AddPatientContext";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import useClinicianRequestTypes from "../../../hooks/useClinicianRequestTypes";
import useConnectedStaffsAll from "../../../hooks/useConnectedStaffsAll";
import useDefaultTestDeadlinePreference from "../../../hooks/useDefaultTestDeadlinePreference";
import RoundLabelledCheckbox from "../../../components/dialogs/ChallengePromptModal/RoundLabelledCheckbox/RoundLabelledCheckbox";
import { AddNewClinicianModal } from "./AddNewClinicianModal";
import { ReferringClinicianData } from "./AddPatientDetails";
import { SearchField } from "../../../components/SearchField/SearchField";
import { AddPatientFormFieldValues } from "./AddPatient"
import s from "../../../components/dialogs/ChallengePromptModal/ChallengePromptForm.module.scss";
import { authFetch, AppError } from "../../../utils/utils";
import { Information } from "../../../components/tooltips/Information/Information";
import { ClinicianRequestType } from "../../../utils/data-classes/ClinicianRequestType";
import FormErrorMessage from "../../../components/errors/FormErrorMessage/FormErrorMessage";
import { LanguageSelectWidget } from "../../../components/menus/LanguageSelect/LanguageSelect";

const minLimits = {
  duration: {
    min: 1,
    max: 360,
  },
  breathInterval: {
    min: 1,
    max: 30,
  },
  testDeadline: {
    min: 1,
    max: 21,
  }
}

export const SelectChallenge = () => {
  const form = useAddPatientFormContext();
  const { register, setValue, watch, trigger, formState: { errors } } = form;
  const { dispatch, state: { processing } } = useContext(AddPatientContext);
  const { clinicianRequestTypes } = useClinicianRequestTypes();
  const [requestType, setRequestType] = useState<ClinicianRequestType>();
  const { sibo, csid, nonSibo } = getSplitRequestTypes(clinicianRequestTypes);

  useEffect(() => {
    const selectedId = form.watch("challenge.crtId");
    if (!selectedId || !clinicianRequestTypes) return;

    const selectedType = clinicianRequestTypes.find(c => c.crtId === selectedId);
    if (selectedType) {
      setRequestType(selectedType);
    }
  }, [form.watch("challenge.crtId"), clinicianRequestTypes]);


  return (
    <div className={s.chalPromptForm}>
      <BasicOptions form={form} sibo={sibo} csid={csid} nonSibo={nonSibo} />
      <AdvancedOptions form={form} requestType={requestType} />

      <div className={s.btnGroup}>
        <button
          type="button"
          className={s.btnSecondary}
          onClick={() => dispatch({ type: "SET_STAGE", payload: "ORDER_DEVICE" })}
        >
          Back
        </button>
        <button
          type="submit"
          className={s.btnPrimary}
          disabled={processing}
        >
          {processing ? "Processing..." : "Submit"}
        </button>
      </div>
    </div>
  );
};


const ClinicianSection = ({ form }: { form: UseFormReturn<AddPatientFormFieldValues> }) => {
  const { state: { patient } } = useContext(AddPatientContext);
  const { state: { loginToken, staff } } = useContext(LoginContext);
  // Load all staffs under the provider
  const { connectedStaffsAll, user } = useConnectedStaffsAll();
  const [searchValue, setSearchValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [showAddNewModal, setShowAddNewModal] = useState(false);

  useEffect(() => {
    if (!connectedStaffsAll) return;

    const referringClinician = form.getValues("referringClinician");
    if (!referringClinician?.stfid) return;

    const defaultStaff = connectedStaffsAll.find(
      (s) => s.stfid === referringClinician.stfid?.toString()
    );
    if (!defaultStaff) return;

    const label = [defaultStaff.salutation, defaultStaff.firstName, defaultStaff.lastName]
      .filter(Boolean)
      .join(" ");

    // Only update if search value differs
    if (searchValue !== label) {
      setSearchValue(label);
    }

    const currentRefStfId = form.getValues("challenge.referringStfid");
    const newRefStfId = parseInt(defaultStaff.stfid);

    // Only update if value changed
    if (currentRefStfId !== newRefStfId) {
      form.setValue("challenge.referringStfid", newRefStfId);
    }
  }, [connectedStaffsAll]);



  useEffect(() => {
    console.log("referringStfid watch:", form.watch("challenge.referringStfid"));
  }, [form.watch("challenge.referringStfid")]);

  const handleAddNew = () => {
    setShowAddNewModal(true);
    setIsOpen(false);
  };

  const handleNewClinicianSave = (newClinician: ReferringClinicianData) => {
    if (newClinician.stfid) {
      form.setValue('challenge.referringStfid', parseInt(newClinician.stfid));
      const newClinicianLabel = [
        newClinician.salutation,
        newClinician.name,
        newClinician.surname
      ].filter(Boolean).join(' ');
      setSearchValue(newClinicianLabel);
    }
    setShowAddNewModal(false);
  };

  return (
    <div className={s.clinicianField}>
      <div className={s.labelContainer}>
        <label className={s.clinicianLabel}>Clinician</label>
        <Tooltip data="The clinician's name displayed here will appear on the test report. You can edit this field if needed." />
      </div>
      <SearchField
        items={connectedStaffsAll?.map(staff => ({
          label: [staff.salutation, staff.firstName, staff.lastName].filter(Boolean).join(' '),
          value: staff,
          isBold: staff.link,
        })) || []}
        placeholder="Search for a referring clinician..."
        onSelect={(staff) => form.setValue('challenge.referringStfid', parseInt(staff.stfid))}
        onAddNew={handleAddNew}
        addNewText="Add new referring clinician"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        size="small"
      />

      {showAddNewModal && (
        <AddNewClinicianModal
          onClose={() => setShowAddNewModal(false)}
          onSave={handleNewClinicianSave}
        />
      )}
    </div>
  );
};

const BasicOptions = ({ form, sibo, csid, nonSibo }: {
  form: UseFormReturn<AddPatientFormFieldValues, any>;
  sibo: ClinicianRequestType[];
  csid: ClinicianRequestType[];
  nonSibo: ClinicianRequestType[];
}) => {
  const allTests = [
    ...sibo.map(test => ({ label: `SIBO - ${test.label || "Unnamed Test"}`, value: test.crtId })),
    ...csid.map(test => ({ label: `CSID - ${test.label || "Unnamed Test"}`, value: test.crtId })),
    ...nonSibo.map(test => ({ label: `Food Intolerance - ${test.label || "Unnamed Test"}`, value: test.crtId }))
  ];

  return (
    <div className={s.basicOptions}>
      <h1>Select Test</h1>
      <p>Please select the substrate you would like the patient to use for their test:</p>
      <select
        className={s.selectDropdown}
        onChange={(e) => form.setValue("challenge.crtId", parseInt(e.target.value))}
        value={form.watch("challenge.crtId") || ""}
      >
        <option value="" disabled>Select a test...</option>
        {allTests.map((test, i) => (
          <option key={i} value={test.value}>{test.label}</option>
        ))}
      </select>
      <FormErrorMessage errors={form.formState.errors} name="challenge.crtId" />
      <div className={s.languageSelect}>
        <LanguageSelectWidget control={form.control} name="lang" selected={form.watch("lang")} />
      </div>
      <FormErrorMessage errors={form.formState.errors} name="lang" />
    </div>
  );
};

const AdvancedOptions = ({ form, requestType }: { form: UseFormReturn<AddPatientFormFieldValues, any>, requestType: ClinicianRequestType | undefined }) => (
  <div className={s.advancedOptions}>
    <h1>Advanced Options</h1>
    <ClinicianSection form={form} />
    <p>To change the test parameters, use the dropdown menus below.</p>
    <div className={s.advancedOptionsInputs}>
      <TestDeadlineInput form={form} requestType={requestType} />
      <FormErrorMessage errors={form.formState.errors} name="challenge.testDeadlineDays" />
      <DurationInput form={form} requestType={requestType} />
      <FormErrorMessage errors={form.formState.errors} name="challenge.durationMins" />
      <BreathIntervalInput form={form} requestType={requestType} />
      <FormErrorMessage errors={form.formState.errors} name="challenge.intervalMins" />
      <FinishChalCheckbox form={form} />
      <FormErrorMessage errors={form.formState.errors} name="challenge.finishChalOnTwoSuccessivePositive" />
    </div>
  </div>
);


const testDeadlineDaysOptions = [7, 14, 21];

const TestDeadlineInput = ({ form, requestType }: { form: UseFormReturn<AddPatientFormFieldValues, any>, requestType: ClinicianRequestType | undefined }) => {
  const { watch, register, setValue } = form;
  // Call the hook directly in this component
  const { defaultTestDeadlinePreference } = useDefaultTestDeadlinePreference();
  console.log(requestType);

  useEffect(() => {
    // Only set the value if there's a requestType selected and testDeadlineDays isn't already set
    if (requestType) {
      // If we have a preference from the database, use it
      if (defaultTestDeadlinePreference && defaultTestDeadlinePreference.length > 0) {
        const firstDeadline = defaultTestDeadlinePreference[0].testDeadline;
        setValue("challenge.testDeadlineDays", parseInt(firstDeadline));
      }
      // If no preference and no value set yet, use default
      else if (!watch("challenge.testDeadlineDays")) {
        setValue("challenge.testDeadlineDays", 14);
      }
    }
  }, [requestType, defaultTestDeadlinePreference, setValue, watch]);

  return (
    <div className={s.numericInputContainer}>
      <label htmlFor="chal-testDeadline">Test deadline:</label>
      <select id="chal-testDeadline" value={watch("challenge.testDeadlineDays") || ""} disabled={!watch("challenge.crtId")} {...register("challenge.testDeadlineDays", {
        required: "testDeadline is required",
        valueAsNumber: true,
        min: {
          value: minLimits.testDeadline.min,
          message: `Minimum testDeadline in days is ${minLimits.testDeadline.min}`
        },
        max: {
          value: minLimits.testDeadline.max,
          message: `Maximum testDeadline in days is ${minLimits.testDeadline.max}`,
        }
      })}>
        {!requestType && <option value=""></option>}
        {requestType && testDeadlineDaysOptions.map((n, i) => <option key={i} value={n}>{n} days</option>)}
      </select>
      <Tooltip data="The patient will be requested to complete the test within this timeframe, and will be reminded to do so. They will still be able to test after this deadline." />
    </div>
  );
}

const durationMinsOptions = [120, 180, 240, 300];

const DurationInput = ({ form, requestType }: { form: UseFormReturn<AddPatientFormFieldValues, any>, requestType: ClinicianRequestType | undefined }) => {
  const { watch, register, setValue } = form;

  useEffect(() => {
    setValue("challenge.durationMins", defaultDuration(requestType));
  }, [requestType]);

  return (
    <div className={s.numericInputContainer}>
      <label htmlFor="chal-duration">Test duration:</label>
      <select id="chal-duration" defaultValue="" disabled={!watch("challenge.crtId")} {...register("challenge.durationMins", {
        required: "Duration is required",
        valueAsNumber: true,
        min: {
          value: minLimits.duration.min,
          message: `Minimum duration in minutes is ${minLimits.duration.min}`
        },
        max: {
          value: minLimits.duration.max,
          message: `Maximum duration in minutes is ${minLimits.duration.max}`,
        }
      })}>
        {!requestType && <option value=""></option>}
        {requestType && durationMinsOptions.map((n, i) => <option key={i} value={n}>{n} minutes</option>)}
      </select>
      <Tooltip data="Gap between first reading and last reading." />
    </div>
  );
}

function defaultDuration(requestType: ClinicianRequestType | undefined): number {
  switch (requestType?.label) {
    case "Lactulose": return 120;
    case "Glucose": return 120;
    default: return 180;
  }
}

function defaultInterval(requestType: ClinicianRequestType | undefined): number {
  switch (requestType?.label) {
    case "Inulin": return 30;
    default: return 15;
  }
}

const breathIntervalOptions = [15, 20, 30];

function allowedIntervals(duration: number) {
  if (duration >= 300) return [30];
  return breathIntervalOptions;
}

const BreathIntervalInput = ({ form, requestType }: { form: UseFormReturn<AddPatientFormFieldValues, any>, requestType: ClinicianRequestType | undefined }) => {
  form.setValue("challenge.intervalMins", defaultInterval(requestType));
  return (
    <div className={s.numericInputContainer}>
      <label htmlFor="breath-interval">Breath reading interval:</label>
      <select id="breath-interval" defaultValue={defaultInterval(requestType)} {...form.register("challenge.intervalMins", {
        required: "Breath reading interval is required",
        valueAsNumber: true,
        min: {
          value: minLimits.breathInterval.min,
          message: `Minimum breath reading interval minutes is ${minLimits.breathInterval.min}`,
        },
        max: {
          value: minLimits.breathInterval.max,
          message: `Maximum breath reading interval minutes is $${minLimits.breathInterval.max}`,
        }
      })}>
        {requestType && allowedIntervals(form.watch("challenge.durationMins")).map((n, i) => <option key={i} value={n}>{n} minutes</option>)}
      </select>
      <Tooltip data={getBreathIntervalTooltip(requestType, form.watch("challenge.durationMins"))} />
    </div>
  );
}

function getBreathIntervalTooltip(requestType: ClinicianRequestType | undefined, chalDuration: number): string {
  const baseTooltip = "Gap between each consecutive reading.";

  if (requestType?.label === "Inulin")
    return `${baseTooltip} Breath interval will change to 15 minutes once a rise is detected. After 3 hours patients are asked if they would like to continue testing. Patients are permitted to eat a small low FODMAP meal at this point.`;

  if (chalDuration >= 300)
    return `${baseTooltip} Breath interval will change to 15 minutes once a rise is detected.`;

  return baseTooltip;
}

const Tooltip = ({ data }: { data: string | undefined }) => {
  return <span>{data !== undefined && <Information data={data} aria-label="Information icon" />}</span>
}

const FinishChalCheckbox = ({ form }: { form: UseFormReturn<AddPatientFormFieldValues, any> }) => (
  <RoundLabelledCheckbox id="finish-challenge-checkbox" reg={form.register("challenge.finishChalOnTwoSuccessivePositive")} >
    Finish test when two successive breath readings reach positive criteria.
  </RoundLabelledCheckbox>
)

const getSplitRequestTypes = (clinicianRequestTypes: ClinicianRequestType[] = []): ClinicianRequestTypeSplit => {
  return clinicianRequestTypes.reduce<ClinicianRequestTypeSplit>(
    (acc, type) => {
      if (type.isSIBO()) acc.sibo.push(type);
      else if (type.isCSID()) acc.csid.push(type);
      else acc.nonSibo.push(type);
      return acc;
    },
    { sibo: [], csid: [], nonSibo: [] }
  );
};


type ClinicianRequestTypeSplit = {
  sibo: ClinicianRequestType[];
  csid: ClinicianRequestType[];
  nonSibo: ClinicianRequestType[];
}
