import { useContext, useEffect, useState } from "react";
import { AppError, authFetch } from "../utils/utils";
import useErrorState from "./useErrorState";
import { LoginContext } from "../contexts/LoginContext/LoginContext";

export interface defaultTestDeadlinePreference {
    testDeadline: string;
    prefType: string;
}

const useDefaultTestDeadlinePreference = () => {
    const [defaultTestDeadlinePreference, setDefaultTestDeadlinePreference] = useState<defaultTestDeadlinePreference[] | undefined>();
    const [error, setError] = useErrorState();
    const { state: { loginToken, staff } } = useContext(LoginContext);

    const fetchDefaultTestDeadlinePreference = async (token: string, signal?: AbortSignal) => {
        try {
            if (!staff?.stfid) return;

            const apiUrl = `/api/get-default-test-deadline?stfid=${staff.stfid}`; 
            const res = await authFetch(token, apiUrl, { signal });

            if (!res.ok) {
                console.error("Fetch failed:", res.status);
                throw new AppError(res.status, "Failed to fetch test deadlines");
            }

            const data = await res.json();
            console.log("API Response:", data);

            if (data.error) throw new AppError(res.status, data.error);

            setDefaultTestDeadlinePreference(data.getDefaultTestDeadline.map(createdefaultTestDeadlinePreference));
        } catch (err) {
            console.error("Error fetching test deadline:", err);
            if (err instanceof Error && err.name !== "AbortError") {
                setError(err);
            }
        }
    };

    useEffect(() => {
        if (!loginToken) return; // Prevent invalid calls
        const abortController = new AbortController();
        fetchDefaultTestDeadlinePreference(loginToken, abortController.signal);
        return () => abortController.abort();
    }, [loginToken]);

    const refreshDefaultTestDeadlinePreference = () => {
        if (loginToken) {
            fetchDefaultTestDeadlinePreference(loginToken);
        }
    };

    return { defaultTestDeadlinePreference, error, refreshDefaultTestDeadlinePreference };
};

export function createdefaultTestDeadlinePreference(getDefaultTestDeadline: any): defaultTestDeadlinePreference {
    return { 
        testDeadline: getDefaultTestDeadline.testDeadline ?? "",
        prefType: getDefaultTestDeadline.prefType ?? "",
    };
}

export default useDefaultTestDeadlinePreference;
